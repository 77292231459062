import {
  FaReact,
  FaVuejs,
  FaPython,
  FaNodeJs,
  FaUnity,
  FaHtml5,
  FaCss3Alt,
} from 'react-icons/fa';
import ezBridgeImage from 'assets/projects/ezbridge.png';
import rpsImg from 'assets/projects/rps.png';
import talkOnFactsImg from 'assets/projects/talkonfacts.png';
import tradeHatImg from 'assets/projects/tradehat.jpeg';
import glowBeyondImg from 'assets/projects/glowbeyond.png';
import propiaImg from 'assets/projects/propia.png';
import oxflayImg from 'assets/projects/oxflay.png';
import sanarsImg from 'assets/projects/sanars.png';
import mightyFinessImg from 'assets/projects/mightyfitness.png';
import { APP_CONSTS } from 'common/constants/app';

export const INTRO_SECTION = Object.freeze({
  TITLE: 'Innovation, Compiled.',
  DESCRIPTION:
    'Pseudosoft: Where Imagination Meets Innovation. At Pseudosoft, we are a forward-thinking software company dedicated to developing transformative solutions that reshape the digital world. Our passion for excellence drives us to fuse creativity with technology, ensuring that every experience is elevated and impactful. Step into the future with Pseudosoft, where the possibilities of innovation are limitless.',
});

export const SERVICES_IN_DETAIL = Object.freeze([
  {
    title: 'AI/ML INNOVATION HUB',
    description:
      'Drive innovation with our AI/ML specialists, powering your projects with cutting-edge technologies. Harness the potential of artificial intelligence and machine learning for predictive analytics, automation, and data-driven insights. Transform your business landscape with our comprehensive solutions, tailored to elevate your industry standing.',
    mapKey: APP_CONSTS.IMG_KEYS.AI,
  },
  {
    title: 'WEB DEVELOPMENT ACCELERATORS',
    imgOrder: 1,
    description:
      'Fuel your web projects with our talented developers, speeding up development and expanding functionality effortlessly. Elevate your web solutions with seamless integration and rapid deployment.',
    mapKey: APP_CONSTS.IMG_KEYS.WEB_DEV,
  },
  {
    title: 'APP DEVELOPMENT MASTERY',
    description:
      'Supercharge your app development endeavors with our skilled team of developers. Accelerate the creation of intuitive and feature-rich applications while ensuring seamless integration and swift deployment. Let our expertise fuel your app projects and deliver standout user experiences.',
    mapKey: APP_CONSTS.IMG_KEYS.APP_DEV,
  },
]);

export const TRAITS = Object.freeze([
  {
    title: 'Innovative Solutions',
    list: [
      'Constant Research: We stay at the cutting edge of technology trends through continuous research and exploration',
      'Creative Thinking: Pseudosoft fosters a culture of creativity, encouraging our team to think outside the box for innovative solutions.',
      'Industry Insight: Our in-depth industry knowledge allows us to anticipate and address the unique challenges faced by businesses.',
    ],
    mapKey: APP_CONSTS.IMG_KEYS.INNOVATIVE_SOLS,
    imgOrder: 1,
  },
  {
    title: 'Customized Approach',
    list: [
      'Tailored solutions designed to meet the unique needs of clients.',
      'In-depth needs assessment identifies challenges and opportunities for personalized solutions.',
      'Flexible strategies adapt to evolving project requirements for customized outcomes.',
    ],
    mapKey: APP_CONSTS.IMG_KEYS.WEB_DEV,
  },
  {
    title: 'Experienced Team',
    list: [
      'Proven track record assures successful project delivery and client satisfaction.',
      'Technical proficiency guarantees implementation of industry best practices in development.',
      'Collaborative team approach ensures seamless communication and collective problem-solving skills.',
    ],
    mapKey: APP_CONSTS.IMG_KEYS.WEB_DEV,
    imgOrder: 1,
  },
]);

export const PROJECTS = Object.freeze({
  TITLE: 'Our Projects',
  DATA: [
    {
      desc: 'EZ Bridge Connect provides influencers with an intuitive dashboard that presents a range of sponsorship opportunities. Browse, select, and engage in partnerships that align with your brand.',
      title: 'Ez Bridge',
      id: 1,
      image: ezBridgeImage,
      link: 'https://ezbridge.io',
    },
    {
      desc: 'A comprehensive stock trading web app with real time monitoring, data driven analysis, and AI powered investment recommendations.',
      title: 'TradeHat',
      id: 2,
      image: tradeHatImg,
      link: 'https://app.tradehat.com',
    },
    {
      desc: 'A Django based website for a Canadian trucking agency offering diverse transport services including residential, commercial, and last mile deliveries.',
      title: 'Rapid Plus Solutions',
      id: 3,
      image: rpsImg,
      link: 'https://rapidplussolutions.com',
    },
    {
      desc: 'A Vue JS and django blog platform offering diverse content on health, fitness, fasion, nature, and more.',
      title: 'Talk On Facts',
      id: 4,
      image: talkOnFactsImg,
      link: 'https://talkonfacts.com',
    },
    {
      desc: 'Glow Beyond: Dubai-based cosmetics e-commerce. With Django and Vue.js. Curated skincare, seamless shopping, sophisticated design, intuitive navigation—elevate skincare routines.',
      title: 'Glow Beyond',
      id: 5,
      image: glowBeyondImg,
      link: 'https://glowbeyond.shop',
    },
    {
      desc: 'Oxlay: Your top destination for high-quality female bags. Stylish collection, real-time tracking, trend analysis, personalized recommendations for the perfect accessory.',
      title: 'Oxflay',
      id: 6,
      image: oxflayImg,
      link: 'https:oxflay.com',
    },
    {
      desc: 'Mighty Fitness: Your go-to for gym equipment. Comprehensive range, immersive shopping, real-time monitoring, data insights, expert recommendations—empowering fitness enthusiasts effectively.',
      id: 7,
      image: mightyFinessImg,
      link: 'https:mightyfitness.shop',
    },
    {
      desc: "Propia: Turkey's top online skincare store. Curated solutions, real-time inventory, expert recommendations. Achieve healthier, glowing skin effortlessly.",
      title: 'Propia',
      id: 8,
      image: propiaImg,
      link: 'https://propia.com/',
    },
    {
      desc: 'Sanars: Your top UK online jewelry destination. Curated selection, real-time updates, personalized recommendations. Elevate your style effortlessly with our stunning pieces.',
      title: 'Sanars',
      id: 9,
      image: sanarsImg,
      link: 'https://sanars.co.uk/',
    },
  ],
});

export const SERVICES = Object.freeze({
  TITLE: 'Our Services',
  DATA: [
    {
      id: 1,
      title: 'Web Development',
      description:
        "Elevate your online presence with Pseudosoft's expert web development services. We create responsive, visually stunning websites tailored to your business goals.",
    },
    {
      id: 2,
      title: 'App Development',
      description:
        "Transform your ideas into powerful mobile experiences with Pseudosoft's app development. Our team ensures your applications meet the highest standards of performance, security, and user engagement.",
    },
    {
      id: 3,
      title: 'AI/ML and VR Solutions',
      description:
        'Pseudosoft pioneers AI/ML integration for predictive analytics and automation. Dive into immersive virtual reality experiences with our cutting-edge solutions, bringing the future to your fingertips.',
    },
    {
      id: 4,
      title: 'Performance Marketing',
      description: `Elevate your conversions with Pseudosoft's 
        tailored performance marketing. Drive impactful results with our 
        targeted advertising and real-time analytics.`,
    },
    {
      id: 5,
      title: 'SEO',
      description: `Increase your search presence with Pseudosoft's SEO expertise. 
        Achieve higher rankings and attract quality traffic with our optimized 
        strategies.`,
    },
    {
      id: 6,
      title: 'Ecommerce Store Development ',
      description: `Grow your retail business online 
        with Pseudosoft's ecommerce development. Experience custom, user-
        friendly shopping platforms that convert.`,
    },
  ],
});

export const TECHNOLOGIES_ICONS = Object.freeze([
  { name: 'React', icon: <FaReact size={'4rem'} color="#61dafb" /> },
  { name: 'Vue.js', icon: <FaVuejs size={'4rem'} color="#41b883" /> },
  { name: 'Python', icon: <FaPython size={'4rem'} color="#3776ab" /> },
  { name: 'Node.js', icon: <FaNodeJs size={'4rem'} color="#8cc84b" /> },
  { name: 'Unity', icon: <FaUnity size={'4rem'} color="#000000" /> },
  { name: 'HTML5', icon: <FaHtml5 size={'4rem'} color="#e34c26" /> },
  { name: 'CSS3', icon: <FaCss3Alt size={'4rem'} color="#1572b6" /> },
]);
