export const ROUTE_CONSTS = Object.freeze({
  BASE: {
    NAME: 'base',
    PATH: '/',
  },
  PORTFOLIO: {
    NAME: 'portfolio',
    PATH: '',
  },
});
