import axios from 'axios';
import { APP_CONSTS } from 'common/constants/app';

export async function postConstactMessage(payload = {}) {
  try {
    await axios.post(APP_CONSTS.CONTACT_US_FORM_URL, payload, {
      headers: { Accept: 'application/json' },
    });
  } catch (e) {
    throw Error(e);
  }
}
