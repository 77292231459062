import { useToast } from '@chakra-ui/react';
import { useRef } from 'react';

export default function useNotification({ position = 'top-right' } = {}) {
  const toast = useToast();
  const toastIdRef = useRef();

  function showSuccessNotification({ description = 'Success' } = {}) {
    toastIdRef.current = toast({
      description,
      position,
      status: 'success',
    });
  }

  function showErrorNotification({ description = 'Success' } = {}) {
    toastIdRef.current = toast({
      description,
      position,
      status: 'error',
    });
  }

  return {
    showSuccessNotification,
    showErrorNotification,
  };
}
