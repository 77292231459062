import React, { useState } from 'react';
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Flex,
  Textarea,
  Card,
  CardBody,
  Heading,
} from '@chakra-ui/react';
import useNotification from 'hooks/useNotification';
import { postConstactMessage } from 'services/contactUs';
import { FOOTER_CONSTS } from './constants';
import { useAxios } from 'hooks/useAxios';

 const Footer = ({ ...props }) => {
  const { showSuccessNotification, showErrorNotification } = useNotification();

  const { loading, initFetch } = useAxios();

  const initialFormState = {
    email: '',
    fullName: '',
    message: '',
  };
  const [formData, setFormData] = useState(initialFormState);
  const [formErrors] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitContactUsForm = async e => {
    e.preventDefault();
    try {
      await initFetch(formData, postConstactMessage);
      setFormData(initialFormState);
      showSuccessNotification({
        description: FOOTER_CONSTS.NOTIFICATIONS.CONTACT_US.SUCCESS,
      });
    } catch (error) {
      showErrorNotification({
        description: FOOTER_CONSTS.NOTIFICATIONS.CONTACT_US.ERROR,
      });
    }
  };

  return (
    <Box as="footer" id="footer" role="footer" {...props}>
      <Flex direction="row" justify="center">
        <Card border={true} width="100%">
          <Flex justify="center" pt="20px">
            <Heading size="lg">Contact Us</Heading>
          </Flex>
          <CardBody>
            <Container maxW="700">
              <form onSubmit={submitContactUsForm} id="form">
                {/* Email Field */}
                <FormControl id="email" isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Your email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {/* Validation Error Message */}
                  <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                </FormControl>

                {/* Full Name Field */}
                <FormControl id="full-name" isRequired>
                  <FormLabel>Full Name</FormLabel>
                  <Input
                    type="text"
                    name="fullName"
                    placeholder="Your name"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                  {/* Validation Error Message */}
                  <FormErrorMessage>{formErrors.fullName}</FormErrorMessage>
                </FormControl>

                {/* Message Field */}
                <FormControl id="message" isRequired>
                  <FormLabel>Message</FormLabel>
                  <Textarea
                    type="text"
                    name="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  {/* Validation Error Message */}
                  <FormErrorMessage>{formErrors.message}</FormErrorMessage>
                </FormControl>

                {/* Submit Button */}
                <Flex justify="end">
                  <Button mt={4} isLoading={loading} type="submit">
                    Submit
                  </Button>
                </Flex>
              </form>
            </Container>
            <Flex mt="30px" justifyContent="center">
              © 2021 Pseudosoft – Software Development. All rights reserved.
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </Box>
  );
};

export default Footer;
