import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  useBreakpointValue,
  Text,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { HamburgerIcon } from '@chakra-ui/icons';

import logoBlack from '../../assets/logo-black-fulltext.png';
import logoWhite from '../../assets/logo-white-fulltext.png';

export const Navbar = props => {
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const { colorMode } = useColorMode();

  const links = [
    {
      label: 'Services',
      target: 'services',
      offset: -170,
    },
    {
      label: 'Projects',
      target: 'projects',
      offset: -130,
    },
    {
      label: 'Contact Us',
      target: 'footer',
      offset: -100,
    },
  ];

  const scrollTo = link => {
    const { target, offset = 0 } = link;
    const element = document.getElementById(target);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY + offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box
      as="nav"
      role="nav"
      {...props}
      position="sticky"
      top="0"
      zIndex="sticky"
      bgColor={colorMode === 'light' ? 'white' : 'gray.800'}
    >
      <Container maxW="1080">
        <Flex
          paddingY="10px"
          justify={'space-between'}
          align={'center'}
          direction="row"
        >
          <Box>
            <Flex alignItems="center">
              <Text fontSize='2xl' ><strong>Pseudosoft</strong></Text>
              {/* <Image
                alt="pseudosoft"
                src={colorMode === 'light' ? logoBlack : logoWhite}
                maxW="160px"
              ></Image> */}
            </Flex>
          </Box>
          <Flex gap="10px" align="center">
            {isMobile ? (
              <Menu>
                <MenuButton
                  variant="ghost"
                  as={Button}
                  rightIcon={<HamburgerIcon />}
                ></MenuButton>
                <MenuList>
                  {links.map(link => {
                    return (
                      <MenuItem key={link.label} onClick={() => scrollTo(link)}>
                        {link.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            ) : (
              links.map(link => {
                return (
                  <Button
                    variant="ghost"
                    key={link.label}
                    colorScheme="gray"
                    onClick={() => scrollTo(link)}
                  >
                    {link.label}
                  </Button>
                );
              })
            )}
            <ColorModeSwitcher />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
