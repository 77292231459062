import { Box } from '@chakra-ui/react';
import IntroSection from 'components/IntroSection/IntroSection';
import Traits from 'components/Services/Traits';
import ProjectsList from 'components/Projects/ProjectsList';
import ServiceDetail from 'components/Services/ServiceDetail';
import Services from 'components/Services/Services';

import {
  INTRO_SECTION,
  PROJECTS,
  SERVICES,
  SERVICES_IN_DETAIL,
  TRAITS,
} from './constants';
import { useImageThemeMap } from 'hooks/useImageThemeMap';
import Technologies from 'components/Technologies';

export const Main = props => {
  const { getImageFromTheme } = useImageThemeMap();

  return (
    <Box role="presentation" {...props}>
      <IntroSection
        styleProps={{
          h: '58vh',
        }}
        title={INTRO_SECTION.TITLE}
        description={INTRO_SECTION.DESCRIPTION}
      />
      <Services
        title={SERVICES.TITLE}
        services={SERVICES.DATA}
        styleProps={{
          pb: '40px',
        }}
      />

      {SERVICES_IN_DETAIL.map(({ title, description, mapKey, imgOrder }) => (
        <ServiceDetail
          styleProps={{
            pb: '40px',
          }}
          title={title}
          description={description}
          img={getImageFromTheme(mapKey)}
          imgOrder={imgOrder ?? 0}
        />
      ))}

      <ProjectsList
        title={PROJECTS.TITLE}
        projects={PROJECTS.DATA}
        styleProps={{
          pb: '40px',
        }}
      />

      {TRAITS.map(({ title, list, mapKey, imgOrder }) => (
        <Traits
          title={title}
          list={list}
          imgOrder={imgOrder ?? 0}
          img={getImageFromTheme(mapKey)}
        />
      ))}

      <Technologies />
    </Box>
  );
};
