import {
  Box,
  Card,
  CardBody,
  Container,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';

export default function Services({ styleProps, title, services }) {
  const [expand, setExpand] = useState({});

  function toggleExpand(id) {
    setExpand(prevState => {
      return {
        ...prevState,
        [id]: !prevState[id],
      };
    });
  }

  return (
    <Box id="services" role="article" {...styleProps}>
      <Container maxW="1080">
        <Box textAlign="center" pb="30px">
          <Heading size="lg">{title}</Heading>
        </Box>
        <Box>
          <SimpleGrid
            columns={{
              sm: 3,
              base: 1,
            }}
            spacing={5}
          >
            {services?.map(({ id, title, description }) => {
              return (
                <Card
                  key={id}
                  cursor="pointer"
                  borderRadius={16}
                  onMouseOver={() => toggleExpand(id ?? title)}
                  onMouseOut={() => toggleExpand(id ?? title)}
                >
                  <CardBody>
                    <Box>
                      <Heading size="xs">{title}</Heading>
                      <Text pt="2" fontSize="sm" noOfLines={!expand[id] && 3}>
                        {description}
                      </Text>
                    </Box>
                  </CardBody>
                </Card>
              );
            })}
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
}
