// TITLE: SERVICE DETAIL IMAGES

import aiLight from 'assets/gifs/ai/white.gif';
import aiDark from 'assets/gifs/ai/dark.gif';

import appDevLight from 'assets/gifs/app-dev/light.png';
import appDevDark from 'assets/gifs/app-dev/dark.png';

import customApproachLight from 'assets/gifs/custom-approach/white.gif';
import customApproachDark from 'assets/gifs/custom-approach/dark.gif';

import innovationLight from 'assets/gifs/innovation/white.gif';
import innovationDark from 'assets/gifs/innovation/dark.gif';

import innovativeSolsLight from 'assets/gifs/innovative-solutions/white.gif';
import innovativeSolsDark from 'assets/gifs/innovative-solutions/dark.gif';

import teamLight from 'assets/gifs/team/white.gif';
import teamDark from 'assets/gifs/team/dark.gif';
import webDevLight from 'assets/gifs/web-dev/white.gif';
import webDevdark from 'assets/gifs/web-dev/dark.gif';

export const APP_CONSTS = Object.freeze({
  CONTACT_US_FORM_URL: 'https://getform.io/f/pbnrokrb',
  IMG_KEYS: {
    AI: 'AI',
    APP_DEV: 'APP_DEV',
    CUSTOM_APPROACH: 'CUSTOM_APPROACH',
    INNOVATION: 'INNOVATION',
    INNOVATIVE_SOLS: 'INNOVATIVE_SOLS',
    TEAM: 'TEAM',
    WEB_DEV: 'WEB_DEV',
  },
});

export const THEME_IMG_MAP = Object.freeze({
  [APP_CONSTS.IMG_KEYS.AI]: {
    dark: aiDark,
    light: aiLight,
  },
  [APP_CONSTS.IMG_KEYS.APP_DEV]: {
    dark: appDevDark,
    light: appDevLight,
  },
  [APP_CONSTS.IMG_KEYS.CUSTOM_APPROACH]: {
    dark: customApproachDark,
    light: customApproachLight,
  },
  [APP_CONSTS.IMG_KEYS.INNOVATION]: {
    dark: innovationDark,
    light: innovationLight,
  },
  [APP_CONSTS.IMG_KEYS.INNOVATIVE_SOLS]: {
    dark: innovativeSolsDark,
    light: innovativeSolsLight,
  },
  [APP_CONSTS.IMG_KEYS.TEAM]: {
    dark: teamDark,
    light: teamLight,
  },
  [APP_CONSTS.IMG_KEYS.WEB_DEV]: {
    dark: webDevdark,
    light: webDevLight,
  },
});
