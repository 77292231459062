import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  ScaleFade,
} from '@chakra-ui/react';
import { useInViewport } from 'react-in-viewport';
import { useRef, useState } from 'react';

export default function ProjectCard(props) {
  const { desc, title, image, link } = props;
  const ref = useRef(null);
  const { enterCount } = useInViewport(
    ref,
    { rootMargin: '-200px' },
    { disconnectOnLeave: false },
    {}
  );

  const [expand, setExpand] = useState(false);

  function toggleExpand() {
    setExpand(prevState => !prevState);
  }

  function exploreProject() {
    window.open(link, '_blank');
  }

  return (
    <ScaleFade
      initialScale={0.9}
      in={enterCount > 0}
      whileHover={{ scale: 1.05 }}
    >
      <Box ref={ref} as="main" role="presentation">
        <Card
          onMouseOver={() => toggleExpand()}
          onMouseOut={() => toggleExpand()}
          borderRadius={16}
          cursor="pointer"
          minH={380}
          onClick={() => exploreProject()}
        >
          <CardBody>
            <Flex justify="center" maxH={140}>
              <Image
                objectFit="cover"
                src={image}
                alt="Green double couch with wooden legs"
                borderRadius="md"
              />
            </Flex>
            <Stack mt="6" px="5" spacing="3">
              <Heading size="md">{title}</Heading>
              <Text noOfLines={!expand && 4}>{desc}</Text>
            </Stack>
          </CardBody>
          {/* <CardFooter justify="end">
            <ButtonGroup spacing="2">
              <Button
                variant="ghost"
                colorScheme="blue"
                rightIcon={<ArrowForwardIcon />}
                onClick={() => exploreProject()}
              >
                Explore
              </Button>
            </ButtonGroup>
          </CardFooter> */}
        </Card>
      </Box>
    </ScaleFade>
  );
}
