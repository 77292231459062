import { useState } from 'react';

export function useAxios() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const initFetch = async (payload, service) => {
    setLoading(true);
    try {
      const response = await service(payload);
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, initFetch };
}
