import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { ROUTE_CONSTS } from 'common/constants/routes';
import { MainLayout } from 'layouts/MainLayout';

import { Main } from 'pages/Main/Main';
import Error404 from 'pages/Error/Error404';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path={ROUTE_CONSTS.BASE.PATH}
        element={<MainLayout />}
        caseSensitive
      >
        <Route
          path={ROUTE_CONSTS.PORTFOLIO.PATH}
          element={<Main />}
          caseSensitive
        />
      </Route>

      <Route path="*" element={<Error404 />} caseSensitive />
    </Route>
  )
);

export default router;
