import { Flex } from '@chakra-ui/react';
import Footer from 'components/Footer/Footer';
import { Navbar } from 'components/Navbar/Navbar';
import { Outlet } from 'react-router-dom';

export const MainLayout = () => {
  return (
    <Flex direction="column" flex="1">
      <Navbar />
      <Outlet />
      <Footer/>
    </Flex>
  );
};
