import { useRouteError } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Heading,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { ROUTE_CONSTS } from '../../common/constants/routes';
import { Box } from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { ERROR_CONSTS } from './constants';

const Error404 = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <Container
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
    >
      <Box width="100%">
        <Card textAlign="center" borderRadius={16}>
          <CardHeader>
            <WarningTwoIcon fontSize={100} color="red" />
          </CardHeader>
          <CardBody pt={0}>
            <Heading size="lg">{ERROR_CONSTS.HEADING}</Heading>
            <Text pt={3}>{ERROR_CONSTS.MESSAGE}</Text>
          </CardBody>
          <CardFooter pt={0} display="flex" justifyContent="center">
            <Button
              as={Link}
              to={ROUTE_CONSTS.PORTFOLIO.PATH}
              color="red"
              variant="outline"
            >
              Go Back
            </Button>
          </CardFooter>
        </Card>
      </Box>
    </Container>
  );
};

export default Error404;
