import { useColorMode } from '@chakra-ui/react';

const { THEME_IMG_MAP } = require('common/constants/app');

export function useImageThemeMap() {
  const { colorMode } = useColorMode();
  function getImageFromTheme(name) {
    return THEME_IMG_MAP[name][colorMode];
  }
  return {
    getImageFromTheme,
  };
}
