import React from 'react';
import Slider from 'react-slick';
import { Heading, useColorMode } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { TECHNOLOGIES_ICONS } from 'pages/Main/constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Technologies.module.css';

const Technologies = () => {
  const { colorMode } = useColorMode();

  const isDarkMode = colorMode === 'dark';

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    prevArrow: null,
    nextArrow: null,
    appendDots: dots => (
      <div>
        <ul style={{ margin: '0px', color: 'white' }}>{dots}</ul>
      </div>
    ),
  };

  return (
    <section
      id="technologies"
      style={{ paddingBottom: '3rem', marginBottom: '3rem' }}
    >
      <Heading size="lg" textAlign="center" mb="10">
        Technologies We Use
      </Heading>
      <Slider {...settings} style={{ marginLeft: '2rem', marginRight: '2rem' }}>
        {TECHNOLOGIES_ICONS.map((tech, index) => (
          <span key={index}>{tech.icon}</span>
        ))}
      </Slider>
    </section>
  );
};

export default Technologies;
